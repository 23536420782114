import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Activity/Views/WalletHistory/Common/TableHeader.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Activity/Views/WalletHistory/Transfer.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "w-full h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { Status } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Transfers",
  props: {
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  setup(__props) {
    const bridgeStore = useBridgeStore();
    const transactions = computed(
      () => bridgeStore.subaccountTransferBridgeTransactions
    );
    return (_ctx, _cache) => {
      const _component_PartialsActivityViewsWalletHistoryCommonTableHeader = __nuxt_component_0;
      const _component_PartialsActivityViewsWalletHistoryTransfer = __nuxt_component_1;
      const _component_CommonEmptyList = __nuxt_component_2;
      const _component_CommonTableWrapper = __nuxt_component_3;
      const _component_AppHocLoading = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: __props.status,
        "loader-class": __props.status.isLoading() ? "relative" : ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CommonTableWrapper, { "break-md": "" }, {
              default: _withCtx(() => [
                _unref(transactions).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                  _createVNode(_component_PartialsActivityViewsWalletHistoryCommonTableHeader),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(transactions), (transaction, index) => {
                      return _openBlock(), _createBlock(_component_PartialsActivityViewsWalletHistoryTransfer, {
                        key: `transfers-${index}`,
                        transaction
                      }, null, 8, ["transaction"]);
                    }), 128))
                  ])
                ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
                  key: 1,
                  message: _ctx.$t("walletHistory.emptySubaccountTransfers"),
                  class: "pb-4 grow bg-gray-900"
                }, null, 8, ["message"]))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["status", "loader-class"]);
    };
  }
});
